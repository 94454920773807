<template>
  <div class='IndexDatePicker'>
    <template v-if='!isRatioTime'>
      <template v-if="size !== 'small'">
        <a-button
          v-for='type in dateTypeList'
          v-if='showDateTypes.indexOf(type.value) > -1'
          :key='type.value'
          :type="type.value === selectedDateType ? 'primary' : 'default'"
          style='margin-right: 10px'
          @click='handleTypeChange(type.value)'
        >{{ type.label }}
        </a-button>
      </template>
      <template v-else>
         <span
           v-for='type in dateTypeList'
           v-if='showDateTypes.indexOf(type.value) > -1'
           :key='type.value'
           :class='{ selected: type.value === selectedDateType }'
           class='btn-small'
           style='margin-right: 10px'
           @click.prevent='handleTypeChange(type.value)'
         >{{ type.label }}</span>
      </template>
    </template>
    <a-range-picker
      v-model='dataRange'
      :allow-clear='allowClear'
      :size="size || 'default'"
      format='YYYY-MM-DD'
      separator='至'
      style='width: 220px'
      @change='handleDateChange'
    />
  </div>
</template>

<script>
import moment from 'moment'


// 根据类型获取日期范围
function getDateRangeByType(type) {
  switch (type) {
    case 'today':
      return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    case 'yesterday':
      return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')]
    case 'week':
      return [moment().day(0).format('YYYY-MM-DD'), moment().day(7).format('YYYY-MM-DD')]
    case 'month':
      return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    case 'prevMonth':
      return [
        moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      ]
    case 'quarter':
      return [
        moment()
          .month(Math.floor((moment().month()) / 3) * 3)
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment()
          .month(Math.floor((moment().month()) / 3) * 3 + 2)
          .endOf('month')
          .format('YYYY-MM-DD')
      ]
    case 'year':
      return [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')]
    default:
      return []
  }
}

export default {
  name: 'IndexDatePicker',
  props: {
    defaultDateType: {
      type: String,
      default: ''
    },
    defaultDateRange: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: ''
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    showDateTypes: {
      type: String,
      default: 'yesterday,week,month,quarter,year'
    },
    isRatioTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateTypeList: [
        { label: '今日', value: 'today' },
        { label: '昨日', value: 'yesterday' },
        { label: '本周', value: 'week' },
        { label: '本月', value: 'month' },
        { label: '上月', value: 'prevMonth' },
        { label: '本季', value: 'quarter' },
        { label: '本年', value: 'year' }
      ].map(v => ({ ...v, dateRange: getDateRangeByType(v.value) })),
      selectedDateType: '',
      dataRange: []
    }
  },
  mounted() {
    if (this.defaultDateType) this.handleTypeChange(this.defaultDateType)
    if (this.defaultDateRange.length) this.handleDateChange(this.defaultDateRange)
  },
  methods: {
    handleTypeChange(type) {
      const { dateRange } = this.dateTypeList.find(v => type === v.value)
      this.selectedDateType = type
      this.dataRange = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
      this.$emit('date-range-change', this.dataRange)
    },
    handleDateChange(dateRange) {
      this.dataRange = !dateRange.length ? [] : [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
      this.selectedDateType = !dateRange.length ? '' :
        (this.dateTypeList.find(v => v.dateRange[0] === this.dataRange[0] && v.dateRange[1] === this.dataRange[1]) || {}).value || ''

      this.$emit('date-range-change', this.dataRange)
    }
  }
}
</script>

<style lang='less'>
.IndexDatePicker {
  .ant-btn {
    padding: 6px;
  }

  .btn-small {
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &.selected {
      color: #3a7ef5;
    }
  }
}
</style>
