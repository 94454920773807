<template>
  <page-header-wrapper :loading="loading">



  <div class="app-container home" >
    <div class="spin-load" v-if="showLoading">
      <a-spin :spinning="showLoading">
      </a-spin>
    </div>
    <div v-if="!showLoading">
      <div class="app-container-card" v-if="!hasAccount" >
        <a-row :gutter="20">
          <a-col :span="6" :offset="8">
            <a-card shadow="hover">
              <div style="padding: 14px;">
                <a-divider>公司短信申请</a-divider>
                <div class="content">公司短息、与系统深度集成、便捷、优惠</div>
                <div class="bottom">
                  <a-button type="text" class="button" @click="handleAddAccount">申请公司短信</a-button>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div  v-if="hasAccount" class="sms-account-page">
        <a-card :bordered="false" :loading="loading">
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-card size="small" title="账户信息">
                <a-row align="bottom" justify="space-between" type="flex">
                  <a-statistic :value="this.accountInfo.smsTotal" title="短信余量（条）"></a-statistic>
                  <div>
                    <a-button type="primary"  @click="handleShowAccount" style="margin-right: 10px;margin-top: 10px">签名与模板</a-button>
                    <a-button type="primary" @click="buySmsSetMeal" style="margin-right: 10px;margin-top: 10px">购买资源包</a-button>
                  </div>
                </a-row>
              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card size="small" title="短信用量">
                <a-row align="bottom" justify="space-between" type="flex">
                  <a-statistic group-separator="," :value="parseInt(this.allSmsTotalInfo.smsSendTotal)" title="总用量（条）" class="info"></a-statistic>
                  <a-statistic group-separator="," :value="parseInt(this.allSmsTotalInfo.smsSendSuccessTotal)" title="总成功接收（条）" class="info"></a-statistic>
                  <a-statistic group-separator="," :value="parseInt(this.allSmsTotalInfo.smsSendFailTotal)" title="总接收失败（条）" class="info"></a-statistic>
                </a-row>
              </a-card>
            </a-col>
            <a-col :span="24">
              <a-card size="small" title="使用量统计">
                <IndexDatePicker slot="extra" default-date-type="year" @date-range-change="handleDateRangeChange" />
                <a-row align="bottom" justify="space-around" style="margin: 30px 0" type="flex">
                  <a-statistic group-separator="," :value="parseInt(this.dateSmsTotalInfo.smsSendTotal)" title="总用量（条）" class="info"></a-statistic>
                  <a-statistic group-separator="," :value="parseInt(this.dateSmsTotalInfo.smsSendSuccessTotal)" title="总成功接收（条）" class="info"></a-statistic>
                  <a-statistic group-separator="," :value="parseInt(this.dateSmsTotalInfo.smsSendFailTotal)" title="总接收失败（条）" class="info"></a-statistic>
                </a-row>
                <sms-chart ref="smsChart"></sms-chart>
              </a-card>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </div>

    <!--短信资源包-->
    <a-drawer :visible.sync = "mealOpen" width="810"  placement="right"  @close="buySmsSetMealClose" >
      <a-divider orientation="left">
        <b>短信资源包</b>
      </a-divider>
      <recharge  ref="buySmsSetMealDrawer" :channelId="this.accountInfo.id" :has-submit-btn="hasSubmitBtn"></recharge>
      <div class="bottom-control">
        <a-space>
          <a-button :loading="btnLoading" type="primary"   @click="buySmsSetMealSubmit">  提交订单 </a-button>
          <a-button :loading="btnLoading"  @click="buySmsSetMealClose"> 取消  </a-button>
        </a-space>
      </div>


    </a-drawer>
  </div>
  <!--申请开通账户-->
  <a-drawer :visible.sync ="accountOpen" width="600"  placement="right" @close="cancelAccount" >
    <a-divider orientation="left">
      <b>申请开通账户</b>
    </a-divider>
    <a-form-model ref="accountForm" :model="accountForm" :rules="accountRules" laba-width="80px">
      <a-form-model-item label="联系人" prop="nickname">
        <a-input v-model="accountForm.nickname" placeholder="请输入联系人" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile">
        <a-input v-model="accountForm.mobile" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="账户描述" prop="remark">
        <a-input v-model="accountForm.remark" type="textarea" placeholder="请描述您的业务使用场景" />
      </a-form-model-item>
    </a-form-model>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" @click="submitAccountForm">创建</a-button>
        <a-button @click="cancelAccount">取 消</a-button>
      </a-space>
    </div>
  </a-drawer>
  </page-header-wrapper>
</template>

<script>
import { crateAccount,getSmsSetMeal,getAccountInfo} from "@/api/sms/zrwinfo";
import { getSmsTotal,getSmsTotalPieChart,getSmsTotalBarChart } from "@/api/sms/log";
import Recharge from '../channel/Recharge'
import SmsChart from "./smsChart";
import IndexDatePicker from '@/components/IndexDatePicker'
export default {
  name: "OverviewIndex",
  components: {
    Recharge,
    SmsChart,
    IndexDatePicker
  },
  data() {
    return {
      showLoading: true,
      allSmsTotalInfo:{},
      dateSmsTotalInfo:{},
      btnLoading:false,
      hasSubmitBtn: false,
      mealOpen:false,
      accountInfo:{},
      hasAccount: false,
      loading: false,
      total:100,
      accountOpen:false,
      accountForm:{},
      accountRules: {
        nickname: [
          { required: true, message: "联系人不能为空", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" }
        ]
      },
    };
  },
  created() {
    //获取是否有公司短信
    this.getAccountInfo()
  },
  mounted() {
    this.getSmsTotal()
  },
  methods: {
    buySmsSetMealSubmit(){
      this.$refs.buySmsSetMealDrawer.handleSubmit();
    },
    buySmsSetMealClose(){
      this.mealOpen = false;
    },
    buySmsSetMeal(){
      this.mealOpen = true;
    },
    handleAddAccount(){
      this.accountReset();
      this.accountOpen = true
    },
    submitAccountForm(){
      this.$refs["accountForm"].validate(valid => {
        if (valid) {
          crateAccount(this.accountForm).then(response => {
            if(response.code ===1){
              this.accountOpen = false;
              this.$message.success("申请成功");
              this.getAccountInfo();
            }else{
              this.$message.error(response.msg);
            }
          });
        }
      });
    },
    cancelAccount(){
      this.accountOpen = false;
      this.accountReset();
    },
    accountReset() {
      this.accountForm = {
        nickname: null,
        mobile: null,
        password: null,
        remark: null
      };
    },
    getAccountInfo(){
      this.loading = true
      getAccountInfo().then(response =>{
        if(response.code ===1){
          this.accountInfo = response.data
          this.hasAccount = this.accountInfo.hasAccount;
        }else{
          this.$message.error(response.msg);
        }
        this.showLoading = false
        this.loading = false
      })
    },
    handleShowAccount(){
      this.$router.push({
        path:"/sms/zrw-info/index/"+this.accountInfo.id
      })
    },
    getSmsTotal(query){
      getSmsTotal(query).then(response =>{
        if(response.success){
          this.allSmsTotalInfo = response.data
        }else{
          this.$message.errorr(response.msg);
        }
        this.loading = false
      })
    },
    handleDateRangeChange(dateRange) {
      let sendTime = [ dateRange[0]+" 00:00:00",dateRange[1]+" 23:59:59"]
      getSmsTotal({sendTime:sendTime}).then(response =>{
        if(response.success){
          this.dateSmsTotalInfo = response.data
        }else{
          this.$message.error(response.msg);
        }
        this.loading = false
      })
      this.initChart({sendTime:sendTime})
    },
    initChart(query){
      this.$nextTick(() => {
        getSmsTotalBarChart(query).then(response =>{
          console.log("getSmsTotalBarChart",response)
          if(response.success){
            const data = response.data.data.reduce((p, c) => p.concat(...c.data.map((v, i) => ({ name: c.name, date: response.data.category[i], value: +v }))), [])
            this.$refs.smsChart.renderChart({ ref: 'BarChart', type: 'bar', data })
          }else{
            this.$message.error(response.msg);
          }
          this.loading = false
        })

        getSmsTotalPieChart(query).then(response =>{
          if(response.success){
            const   data = response.data.data.map(v => ({ ...v, name: v.name, value: +v.value, percent: +(+v.value / 100).toFixed(2) }))
            this.$refs.smsChart.renderChart({ ref: 'PieChart', type: 'pie', 'data':data })
          }else{
            this.$message.error(response.msg);
          }
          this.loading = false
        })
      })
    }
  }
};
</script>

<style  lang="less">
.home {
  .row-class{
    padding: 10px;
  }
  .app-container-card{
    margin-top: 10em;
    .bottom {
      margin-top: 13px;
      line-height: 12px;
      text-align: center;
    }
    .content{
      font-size: 12px;
      text-align: center;
    }
  }
}
.sms-account-page {
  .ant-statistic-content {
    color: #0081ff;
    text-align: center;
    font-weight: 400;
  }
}
.spin-load {
  text-align: center;
}
</style>
